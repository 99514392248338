import footerImg from '../../assets/images/footer_1.svg';
import { ContactForm } from '../forms';

import styles from '../../assets/styles/contact.module.scss';

const Contact = ({ page = 'banquetHall' }) => {
  return (
    <>
      <img src={footerImg} className={styles.contactImg} />
      <section className={styles.contact}>
        <h2>
          Contactgegevens
        </h2>
        <ContactForm minimal page={page} />
      </section>
    </>
  )
};

export default Contact;
