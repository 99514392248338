import { FormattedMessage } from './';
import { observer } from "mobx-react"

type ErrorProps = {
  error: string;
  activeErrors: any[]
}

const Error = observer(({ error = '', activeErrors = [] }: ErrorProps) => {
  return (
    <>
      {
        error !== '' && (
          <section className="errorinfo">
            <FormattedMessage id={error} />
          </section>
        )
      }

      {
        activeErrors.length > 0 && (
          <section className="errorinfo">
            <h2 className="errortitle">
              Vul de volgende invulvelden in:
            </h2>
            <ul>
              {
                activeErrors.map((error, index) => (
                  <li key={index}>
                    <span>{error.message}</span>
                  </li>
                ))
              }
            </ul>
          </section>
        )
      }
    </>
  )
})

export default Error;

