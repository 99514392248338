import { useMemo, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { CreditCard, Info, Store, ArrowLeft } from "lucide-react";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { QUERIES } from "../../types";
import { CherriesForm, PickupDate } from "../models";
import { Spinner } from "../elements/";
import { formStore, modalStore } from "../../stores";

import styles from "../../assets/styles/cherriesForm.module.scss";
import { FruitboxInfo } from "../modals";

const VIEWS = {
  FORM: "FORM",
  CHOOSE_PAYMENT_METHOD: "CHOOSE_PAYMENT_METHOD",
};

const schema = z.object({
  email: z
    .string()
    .min(1, { message: "Vul een geldig e-mail in. vb (klant@engelendael.be)" })
    .email("Dit is geen geldige e-mail."),
  name: z
    .string()
    .min(3, { message: "Vul een geldige naam in. vb (John Devos)" }),
  address: z
    .string()
    .min(8, { message: "Vul een geldig adres in. vb (Sint-Janspolderijk 14)" }),
  postal: z.string().min(5, {
    message: "Vul een geldige postcode en gemeente in. vb (9982 Sint-Laureins)",
  }),
  telno: z.string().min(5, {
    message: "Vul een geldig telefoonnummer in. vb(+32 479 25 44 60)",
  }),
});

const CherriesFormContainer = () => {
  const [view, setView] = useState(VIEWS.FORM);
  const { openModal } = modalStore;
  const [ordered, setOrdered] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("store");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm<CherriesForm>({
    resolver: zodResolver(schema),
    defaultValues: new CherriesForm(formStore.initalizeForm()),
  });

  watch("date");

  const {
    isLoading,
    isError,
    data: pickupDates,
  } = useQuery<PickupDate[]>({
    queryKey: [QUERIES.GET_PICKUP_TIMES],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const dates = await formStore.getPickupDates();
      return dates;
    },
    onSuccess: (dates: PickupDate[]) => {
      if (!isDirty) {
        const pickupTimes = formStore.getPickupTimes(getValues().date);

        if (dates.length > 0) {
          setValue("date", dates[0].date);
        }

        if (pickupTimes.length > 0) {
          setValue("orderTime", pickupTimes[0].value);
        }
      }
    },
  });

  const pickupTimes = useMemo(() => {
    const pickupTimes = formStore.getPickupTimes(watch("date"));

    if (pickupTimes.length > 0) {
      setValue("orderTime", pickupTimes[0].value);
    }

    return pickupTimes;
  }, [watch("date"), pickupDates]);

  const amounts = useMemo(() => {
    const { date, amount } = getValues();
    const amounts = formStore.getMaxAmount(date);

    const amountValues = amounts.map((a) => a.value);

    if (
      !amountValues.includes(parseInt(`${amount}`)) &&
      amounts.length > 0 &&
      view === VIEWS.FORM
    ) {
      setValue("amount", amounts[0].value);
    }

    return amounts;
  }, [watch("date"), pickupDates]);

  const { mutate: confirmOrder } = useMutation({
    mutationFn: async () => {
      setOrdered(true);
      const data = await formStore.confirmOrder(paymentMethod, getValues());

      if (paymentMethod === "online") {
        window.location.href = data.mollie_url;
      }
    },
    onError: (err: { status: number }) => {
      if (err.status === 409) {
        setErrorMessage("supply_ran_out");
      }

      if (err.status === 500) {
        setOrdered(false);
        setErrorMessage("an_error_occurred");
      }
    },
  });

  const resetForm = () => {
    setView(VIEWS.FORM);
    setErrorMessage(undefined);
    setOrdered(false);
    reset(new CherriesForm(formStore.initalizeForm()));
  };

  const displayFruitboxModal = () => {
    openModal("fruitbox_info");
  };

  const choosePaymentMethod = () => {
    setView(VIEWS.CHOOSE_PAYMENT_METHOD);

    const elem = document.getElementById("cherriesForm");

    if (elem !== null && elem !== undefined) {
      const scrollTop = elem.offsetTop - 100; // Calculate the scroll position 100 pixels above the element
      window.scrollTo({
        top: scrollTop,
      });
    }
  };

  if (isError) {
    return (
      <div>
        Oops... Er is iets misgegaan tijdens het inladen van het
        kersenformulier! Probeer het later opnieuw.
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (ordered && paymentMethod === "store") {
    return (
      <section className={`${styles.state} ${styles.accepted}`}>
        <span>De bestelling is goed doorgekomen.</span>
        <p>
          Bedankt om uw bestelling te plaatsen. U zult een{" "}
          <strong>bestelbewijs ontvangen per E-mail</strong>. Hiermee kunnen we
          uw <strong>bestelling valideren en meegeven</strong>.
        </p>

        <p>
          Indien u deze E-mail niet ontvangen hebt, raden we u aan om in de{" "}
          <strong>SPAM folder</strong> van uw mailbox te kijken.
        </p>
        <p>
          Hartelijke groeten,
          <br />
          <strong className={styles.accent}>Het Engelendael team</strong>
        </p>

        <div className={styles.btn} onClick={resetForm}>
          Plaats een nieuwe bestelling.
        </div>
      </section>
    );
  }

  if (errorMessage !== undefined) {
    return (
      <section className={`${styles.state} ${styles.accepted}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {errorMessage === "" && (
            <strong style={{ marginTop: "20px" }}>
              Er is een probleem opgetreden. Probeer later opnieuw.
            </strong>
          )}

          {errorMessage === "supply_ran_out" && (
            <section>
              Beste kersenklant <br />
              <br /> Momenteel is het <strong>niet</strong> mogelijk om kersen{" "}
              <strong>online</strong> te bestellen. U kan ons steeds telefonisch
              contacteren buiten de openingsuren om te weten te komen of er nog
              kersen beschikbaar zijn (+32 479 25 44 60).
              <br />
              <br />
              <strong>Het Engelendael team</strong>
            </section>
          )}

          {errorMessage === "an_error_occurred" && (
            <section>
              Beste kersenklant <br />
              <br /> Er is een fout opgetreden tijdens uw bestelling. U kunt het
              later opnieuw proberen, of ons telefonisch contacteren op het
              nummer (+32 479 25 44 60).
              <br />
              <br />
              <strong>Het Engelendael team</strong>
              <br />
              <br />
              <div className={styles.btn} onClick={resetForm}>
                Opnieuw proberen
              </div>
            </section>
          )}
        </div>
      </section>
    );
  }

  if (ordered && errorMessage === undefined && paymentMethod === "online") {
    return (
      <section className={styles.loading}>
        <Spinner />
        <span>We verwijzen u door naar uw online betaling...</span>
      </section>
    );
  }

  if (!isLoading && pickupDates.length === 0) {
    return (
      <section>
        Beste kersenklant <br />
        <br /> Momenteel is het <strong>niet</strong> mogelijk om kersen{" "}
        <strong>online</strong> te bestellen. U kan ons steeds telefonisch
        contacteren buiten de openingsuren om te weten te komen of er nog kersen
        beschikbaar zijn (+32 479 25 44 60).
        <br />
        <br />
        <strong>Het Engelendael team</strong>
      </section>
    );
  }

  if (view === VIEWS.FORM) {
    return (
      <>
        <FruitboxInfo />
        <form
          onSubmit={handleSubmit(choosePaymentMethod)}
          className={styles.form}
        >
          <fieldset>
            <div className={styles.pickupGrid}>
              <div>
                <label htmlFor="email">
                  Afhaaldatum<strong>*</strong>
                </label>
                <select {...register("date")}>
                  {pickupDates.map((date) => (
                    <option key={date.date} value={date.date}>
                      {date.displayDate}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="email">
                  Tijdstip<strong>*</strong>
                </label>
                <select {...register("orderTime")}>
                  {pickupTimes.map((time) => (
                    <option value={time.value} key={time.value}>
                      {time.text}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="name">
                  Aantal kg<strong>*</strong>
                </label>
                <select {...register("amount")}>
                  {amounts.map((amount) => (
                    <option value={amount.value} key={amount.value}>
                      {amount.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div className={styles.grid} style={{ marginBottom: '20px' }}> */}
            {/*   <div> */}
            {/*     <div className={styles.fruitBox}> */}
            {/*       <label htmlFor="name">Aantal fruitboxen</label> */}
            {/*       <Info onClick={displayFruitboxModal} /> */}
            {/*     </div> */}

            {/*     <select {...register("boxes")}> */}
            {/*       <option value="0">0 Fruitboxen</option> */}
            {/*       <option value="1">1 Fruitbox</option> */}
            {/*       <option value="2">2 Fruitboxen</option> */}
            {/*       <option value="3">3 Fruitboxen</option> */}
            {/*       <option value="4">4 Fruitboxen</option> */}
            {/*       <option value="5">5 Fruitboxen</option> */}
            {/*       <option value="6">6 Fruitboxen</option> */}
            {/*       <option value="7">7 Fruitboxen</option> */}
            {/*       <option value="8">8 Fruitboxen</option> */}
            {/*       <option value="9">9 Fruitboxen</option> */}
            {/*       <option value="10">10 Fruitboxen</option> */}
            {/*     </select> */}
            {/*   </div> */}
            {/* </div> */}

            <div>
              <div className={`${styles.grid} ${styles.combinedInputs}`}>
                <div>
                  <label htmlFor="name">Voornaam + naam</label>
                  <input
                    id="name"
                    {...register("name")}
                    placeholder="John Doe"
                  />
                  {errors.name && (
                    <span className="inputError">{errors.name.message}</span>
                  )}
                </div>

                <div>
                  <label htmlFor="adres">Adres + huisnummer</label>
                  <input
                    id="adres"
                    {...register("address")}
                    placeholder="Sint-Janspolderdijk 14"
                  />
                  {errors.address && (
                    <span className="inputError">{errors.address.message}</span>
                  )}
                </div>

                <div>
                  <label htmlFor="postal">Postcode + gemeente</label>
                  <input
                    id="postal"
                    {...register("postal")}
                    placeholder="9982 Sint-Laureins"
                  />
                  {errors.postal && (
                    <span className="inputError">{errors.postal.message}</span>
                  )}
                </div>

                <div>
                  <label htmlFor="email">E-mail</label>
                  <input
                    id="email"
                    {...register("email")}
                    placeholder="klant@engelendael.be"
                  />
                  {errors.email && (
                    <span className="inputError">{errors.email.message}</span>
                  )}
                </div>

                <div>
                  <label htmlFor="telno">Telefoonnummer</label>
                  <input
                    id="telno"
                    {...register("telno")}
                    type="tel"
                    placeholder="+32 455 45 21 30"
                  />
                  {errors.telno && (
                    <span className="inputError">{errors.telno.message}</span>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.extraInfo}>
              <label htmlFor="message">Extra mededeling </label>
              <textarea
                {...register("message")}
                placeholder="Vul hier uw extra opmerkingen in."
              />
            </div>

            <label className="container">
              <span>Bewaar mijn gegevens</span>
              <input type="checkbox" {...register("saveDetails")} />
              <span className="checkmark"></span>
            </label>

            <button type="submit">Ga verder met uw bestelling</button>
          </fieldset>
        </form>
      </>
    );
  }

  if (view === VIEWS.CHOOSE_PAYMENT_METHOD) {
    return (
      <section id="paymentMethods" className={styles.paymentMethodsContainer}>
        {!ordered && (
          <>
            <div className={styles.goBack} onClick={() => setView(VIEWS.FORM)}>
              <ArrowLeft />
              <span>Ga terug naar uw besteloverzicht</span>
            </div>
            <h2 className={styles.paymentMethodTitle}>Betaalmethode</h2>
            <div className={styles.paymentMethods}>
              <div
                className={`${styles.paymentMethod} ${paymentMethod === "store" && styles.active}`}
                onClick={() => setPaymentMethod("store")}
              >
                <Store />
                <span>Betaal in de winkel</span>
              </div>

              <div
                className={`${styles.paymentMethod} ${paymentMethod === "online" && styles.active}`}
                onClick={() => setPaymentMethod("online")}
              >
                <CreditCard />
                <span>Betaal online</span>
              </div>
            </div>

            <button onClick={() => confirmOrder()}>Plaats uw bestelling</button>
          </>
        )}
      </section>
    );
  }

  return null;
};

export default CherriesFormContainer;
