import { observable, makeObservable } from 'mobx';

class GlobalStore {
  lang: string;

  constructor() {
    this.lang = 'en';

    makeObservable(this, {
      lang: observable
    })
  }
}

export default new GlobalStore();
