import { Link } from 'react-router-dom';
import { Gallery } from '../partials';

import styles from '../../assets/styles/news.module.scss';

import image from '../../assets/images/test.jpg';
import photo1 from  '../../assets/images/images03062019/photo1.jpg';
import photo2 from  '../../assets/images/images03062019/photo2.jpg';
import photo3 from  '../../assets/images/images03062019/photo3.jpg';
import photo4 from  '../../assets/images/images03062019/photo4.jpg';
import photo5 from  '../../assets/images/images03062019/photo5.jpg';
import photo6 from  '../../assets/images/images03062019/photo6.jpg';
import photo7 from  '../../assets/images/images03062019/photo7.jpg';
import photo8 from  '../../assets/images/images03062019/photo8.jpg';


const News03062019 = () => {

  const images = [
    { id: 1, src: photo1 },
    { id: 2, src: photo2 },
    { id: 3, src: photo3 },
    { id: 4, src: photo4 },
    { id: 5, src: photo5 },
    { id: 6, src: photo6 },
    { id: 7, src: photo7 },
    { id: 8, src: photo8 },
  ];

  return (
    <>
      <div className={styles.imgContainer}>
        <img src={image} alt="" />
      </div>

      <main className={styles.main}>
        <div style={{position: 'relative'}}>
          <h1 className="title">Algemene ledenvergadering landelijk Vlaanderen</h1>
        </div>

        <div className={styles.article}>
          <a href="https://landelijk.vlaanderen/" target="_blank" rel="noopener noreferrer">Landelijk Vlaanderen vzw</a>  organiseerde op 24 mei 2019 haar algemene ledenvergadering in en rond hoeve Engelendael.    <Link to="/files/algemene_leden_vergadering.pdf" target="_blank" download>Lees hier het artikel</Link> dat daarover werd gepubliceerd in Landelijk Vlaanderen. Engelendael is gelegen in het midden van het Oost-Vlaamse krekengebied. Op het voorprogramma stond <a href="https://www.youtube.com/watch?v=wQXYNt2Y8v0&feature=youtu.be&fbclid=IwAR0HatDAEYBtpfj4P3-7NFI8U-GnbmyXEXHuHulOZX2GX95Tch5gHf9tduQ" target="_blank" rel="noopener noreferrer">een geleide wandeling door Marc Govaert</a> . Tijdens de algemene ledenvergadering stond 'het Memorandum van Landelijk Vlaanderen en de Koepel van Vlaamse Bosgroepen' centraal. Samen zijn ze actief in het Aanspreekpunt Privaat Beheer - Natuur en Bos. Ze willen ook in de toekomst maximaal bijdragen aan het realiseren van meer en betere natuur en bos. Ze vragen daartoe een passend kader te scheppen aan de regering. Vonden nog plaats; een toelichting door de experten, een politiek debat, de uitreiking van Wildlife Estates Label en een slottoespraak door Christophe Lenaerts, voorzitter Landelijk Vlaanderen. Alles afgerond door een receptie en netwerkmoment. <Link to="/files/landelijk_vlaanderen_ALV_2019_v3.pdf" target="_blank" download>Het programma kan u hier eens nalezen.</Link>
        </div>

        <h2 className="title">
          Gallerij
        </h2>

        <Gallery images={images}/>
      </main>

    </>
  )
}

export default News03062019;
