// import React, { Component } from 'react';
// import ReactMapboxGl, { Marker, ZoomControl } from "react-mapbox-gl";

// const Map = ReactMapboxGl({ 
//   accessToken: "pk.eyJ1IjoiamVuc3BhdXdlbHMiLCJhIjoiY2phZWFmNnh5MXliajJxbmlmaW9vaGV5YiJ9.9PpdFvYJbD6rXVkPu6F4ag" ,
//   scrollZoom: false,
//   touchZoomRotate: false,
//   boxZoom: false,
//   dragRotate: false, 
//   pitchWithRotate: false,
//   dragPan: false,
//   doubleClickZoom: false
// });


// export default class MapComponent extends Component{
//   constructor(){
//     super();

//     this.state = {
//       zoom: [14.15],
//       center: [3.57, 51.272] 
//     }

//   };

//   onToggleHover = (map: any, cursor: any) => {
//     document.querySelector('.mapboxgl-canvas').style.cursor = cursor;
//   };

//   goToCenter = () => {
//     this.setState({center: [3.57, 51.272]});
//   };

//   render() {
//     const { zoom, center } = this.state;

//     return (
//       <div className="map">
//         <div className="goToCenter" onClick={this.goToCenter}>
//           <i className="fas fa-map-marker-alt" />
//         </div>
//         <Map 
//           style="mapbox://styles/jenspauwels/ck9y6rka123pa1ippfmmiinpf"
//           center={center} 
//           containerStyle={{ height: '500px', width: "100%" }}
//           zoom={zoom}
//           scrollZoom={false}
//           dragPan={false} 
//           touchAction="pan-y"
//           ref={ref => this.map = ref}
//         >
//           <ZoomControl />
//           <Marker
//             coordinates={[3.5695886, 51.2701863]}
//             anchor="bottom"
//             onClick={() => console.log('on click')}
//           >
//             <div className="marker">Hoeve Engelendael</div>
//           </Marker>
//         </Map>
//       </div>
//     )
//   }
// }






// const Map = () => {
//   return (
//     <div>
//     Map
//     </div>
//   )
// };

// export default Map;

import Map, { Marker } from 'react-map-gl';

const API_KEY = "pk.eyJ1IjoiamVuc3BhdXdlbHMiLCJhIjoiY2x3a2JnaXZvMTg1ajJrbXlta29nNHZlMSJ9.ZLtacgDZPXixjcmhX5Fdbw"

const MapComponent = () => {
  return (
    <Map
      mapboxAccessToken={API_KEY}
      initialViewState={{
        longitude: 3.57,
        latitude: 51.272,
        zoom: 14.15
      }}
      style={{ width: '100%', height: 500 }}
      mapStyle="mapbox://styles/jenspauwels/ck9y6rka123pa1ippfmmiinpf"
    >
      <Marker
        longitude={3.5695886}
        latitude={51.2701863}
        anchor="bottom"
        onClick={() => console.log('on click')}
      >
        <div className="marker">Hoeve Engelendael</div>
      </Marker>
    </Map>
  );
};

export default MapComponent;
