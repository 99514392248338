import { useEffect } from 'react';
import { observer } from "mobx-react"
import { Helmet } from "react-helmet";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import styles from '../../assets/styles/news.module.scss'
import image from '../../assets/images/about_us/govaerts.jpg';
import { useRedirect } from '../../hooks';
import { newsStore } from '../../stores';


const News = observer(() => {
  const { newsItems } = newsStore;
  const { redirect } = useRedirect();

  useEffect(() => {
    newsStore.initialize();
  }, [])

  const goToNewsItem = (item: any) => {
    newsStore.selectedItem = item;
    redirect(item.link)
  };

  return (
    <>
      <Helmet>
        <title>Feest- en huwelijkslocatie Hoeve Engelendael</title>
        <meta name="description" content="Engelendael is een hoeve uit de 17de eeuw. Een prachtige locatie om een feest te organiseren. En bekend ook voor het kweken van zoete kersen." />

        <meta name="keywords" content="hoeve,Engelendael,verhuur,kersen,kersenverkoop,landelijk,vlaanderen,sint-laureins,kreekarm,project,herstellen,landgoend,bev-baillet,latour" />
      </Helmet>

      <div className={styles.imgContainer}>
        <img src={image} alt="" style={{ objectPosition: "top" }} />
      </div>

      <main className={styles.page}>
        <h1 className="title">Nieuws</h1>

        <VerticalTimeline>
          {
            newsItems.map(item => (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                style={{cursor: 'pointer'}}
                date={item.date}
              >
                <div onClick={() => goToNewsItem(item)}>
                  <strong>{item.title}</strong>
                  <p className={styles.text} style={{marginBottom: '20px'}}>{item.text.substr(0, 150)}...</p>
                  <span className={styles.readMore}>Lees meer...</span>
                </div>
              </VerticalTimelineElement>
            ))
          }
        </VerticalTimeline>
      </main>  
    </>
  )
});


export default News;

