import styles from '../../assets/styles/news.module.scss';

import image from '../../assets/images/test.jpg';
import kreekarm from '../../assets/images/kreekarm.jpg';

const News11102018 = () => {
  return (
    <>
      <div className={styles.imgContainer}>
        <img src={image} alt="" />
      </div>

      <main className={styles.main}>
        <div style={{position: 'relative'}}>
          <h1 className="title">Project Herstellen Kreekarm </h1>
        </div>

        <div className={styles.imgContainer}>
          <img src={kreekarm} alt="" style={{height: 'inherit'}}/>
        </div>
      </main>
    </>
  )
}

export default News11102018;

