import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useRedirect = () => {
  const navigate = useNavigate();

  const redirect = (location: string) => {
    navigate(location);
  };

  return {
    redirect,
  };
};

export const useWidthValidator = ({ minWidth = 1024 } = {}) => {
  const width = window.innerWidth;
  const [isMobile, setIsMobile] = useState(width <= minWidth);
  const [windowWidth, setWindowWidth] = useState(width);

  useEffect(() => {
    const setWidth = () => {
      const width = window.innerWidth;
      setWindowWidth(width);
      if (width <= minWidth && !isMobile) {
        setIsMobile(true);
      } else if (width > minWidth && isMobile) {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, [isMobile]);

  return {
    isMobile,
    width: windowWidth,
  };
};
