import { Gallery } from '../partials';

import styles from '../../assets/styles/news25112022.module.scss';
import header from '../../assets/images/news25112022/26.jpg';

import photo1 from  '../../assets/images/news25112022/1.jpg';
import photo2 from  '../../assets/images/news25112022/2.jpg';
import photo3 from  '../../assets/images/news25112022/3.jpg';
import photo4 from  '../../assets/images/news25112022/4.jpg';
import photo5 from  '../../assets/images/news25112022/5.jpg';
import photo6 from  '../../assets/images/news25112022/6.jpg';
import photo7 from  '../../assets/images/news25112022/7.jpg';
import photo8 from  '../../assets/images/news25112022/8.jpg';
import photo9 from  '../../assets/images/news25112022/9.jpg';
import photo10 from  '../../assets/images/news25112022/10.jpg';
import photo11 from  '../../assets/images/news25112022/11.jpg';
import photo12 from  '../../assets/images/news25112022/12.jpg';
import photo13 from  '../../assets/images/news25112022/13.jpg';
import photo14 from  '../../assets/images/news25112022/14.jpg';
import photo15 from  '../../assets/images/news25112022/15.jpg';
import photo16 from  '../../assets/images/news25112022/16.jpg';
import photo17 from  '../../assets/images/news25112022/17.jpg';
import photo18 from  '../../assets/images/news25112022/18.jpg';
import photo19 from  '../../assets/images/news25112022/19.jpg';
import photo20 from  '../../assets/images/news25112022/20.jpg';
import photo21 from  '../../assets/images/news25112022/21.jpg';
import photo22 from  '../../assets/images/news25112022/22.jpg';
import photo23 from  '../../assets/images/news25112022/23.jpg';
import photo24 from  '../../assets/images/news25112022/24.jpg';
import photo25 from  '../../assets/images/news25112022/25.jpg';
import photo26 from  '../../assets/images/news25112022/26.jpg';
import photo27 from  '../../assets/images/news25112022/27.jpg';
import photo28 from  '../../assets/images/news25112022/28.jpg';
import photo29 from  '../../assets/images/news25112022/29.jpg';
import photo30 from  '../../assets/images/news25112022/30.jpg';
import photo31 from  '../../assets/images/news25112022/31.jpg';
import photo32 from  '../../assets/images/news25112022/32.jpg';
import photo33 from  '../../assets/images/news25112022/33.jpg';
import photo34 from  '../../assets/images/news25112022/34.jpg';
import photo35 from  '../../assets/images/news25112022/35.jpg';
import photo36 from  '../../assets/images/news25112022/36.jpg';
import photo37 from  '../../assets/images/news25112022/37.jpg';

  const images = [
    { id: 1, src: photo1 },
    { id: 2, src: photo2 },
    { id: 3, src: photo3 },
    { id: 4, src: photo4 },
    { id: 5, src: photo5 },
    { id: 6, src: photo6 },
    { id: 7, src: photo7 },
    { id: 8, src: photo8 },
    { id: 9, src: photo9 },
    { id: 10, src: photo10 },
    { id: 11, src: photo11 },
    { id: 12, src: photo12 },
    { id: 13, src: photo13 },
    { id: 14, src: photo14 },
    { id: 15, src: photo15 },
    { id: 16, src: photo16 },
    { id: 17, src: photo17 },
    { id: 18, src: photo18 },
    { id: 19, src: photo19 },
    { id: 20, src: photo20 },
    { id: 21, src: photo21 },
    { id: 22, src: photo22 },
    { id: 23, src: photo23 },
    { id: 24, src: photo24 },
    { id: 25, src: photo25 },
    { id: 26, src: photo26 },
    { id: 27, src: photo27 },
    { id: 28, src: photo28 },
    { id: 29, src: photo29 },
    { id: 30, src: photo30 },
    { id: 31, src: photo31 },
    { id: 32, src: photo32 },
    { id: 33, src: photo33 },
    { id: 34, src: photo34 },
    { id: 35, src: photo35 },
    { id: 36, src: photo36 },
    { id: 37, src: photo37 },
  ];


const News25112022 = () => {
  return (
    <>
      <div className={styles.imgContainer}>
        <img src={header} alt="" />
      </div>

      <main className={styles.main}>
        <h1 className="title">
          Landschappen Sint-Laureins focust op ontstaan van kreken in Meetjesland
        </h1>

        <h2>
          SINT-JAN-IN-EREMO - <strong>Landschappen Sint-Laureins vzw, een open forum voor de cultuurlandschappelijke ontwikkeling van de gemeente en omgeving, houdt vrijdag 25 november een lezing en vervolgens een driedaagse tentoonstelling over het ontstaan van de kreken in het Meetjesland</strong>
        </h2>

        <h3>
          Emiel Tack
          <sub>
            Maandag 21 november 2022 om 16:29
          </sub>
        </h3>

        <p>
          De vzw Landschappen Sint-Laureins bestaat sinds 2000. “Wijlen Manuel Deudon de le Vielleuze stichtte de vereniging”, zegt huidig voorzitter Marc Govaert. “De man zette zich jaren in om belangrijke plekjes natuur van verdwijning te vrijwaren. Door zijn inzet bleef de Castelijnstraat bestaan als kasseiweg, werd er gediscussieerd over het zinvol rooien en heraanplanten van bomen en werd er met een kritisch oog gekeken naar het afgraven van dijken. Toen Manuel in 2017 overleed, was het voortbestaan van de vereniging geen evidentie. Toen ook corona roet in het eten kwam gooien, werd het nog een stukje moeilijker. Ondertussen staat alles weer op de rails en gaan we verder met de activiteiten.”
        </p>

        <h4>
          Gegeerd wandelgebied
        </h4>

        <p>
          De vzw wil de streek helpen ontplooien als een duurzame, vitale regio waar economie, samenleving, ecologie en cultuurlandschap elkaar bewust versterken. “Geen gemakkelijke opdracht in het licht van de heroriëntering die men met creatie van de landschapsparken voor ogen heeft”, zegt Govaert. “Toch doen we graag onze duit in het zakje en organiseren tal van activiteiten voor leden en niet-leden. Het krekengebied waarin we leven, willen we in een eerstvolgende activiteit in de schijnwerpers zetten. Het is een stil gebied in vergelijking met heel wat andere regio’s in Vlaanderen. Een stukje land dat heel wat kwaliteiten heeft en gegroeid is vanuit de zee. Juist daarom is het een gegeerd wandel- en fietsgebied.”
        </p>

        <p>
          Met de activiteiten van het komende weekend wil de vzw de focus leggen op het ontstaan van de kreken. “Op vrijdag om 19.30 uur is er een lezing van archeoloog Sam De Decker, ondersteund met foto’s en beelden van Sint-Margrietenaar Ludo Goossens. Op zaterdag en zondag, tussen 10 en 16 uur, is er ook een tentoonstelling rond het thema met gegidste wandelingen om en rond de Boerenkreek.”
        </p>

        <p>
          Zowel lezing als tentoonstelling zijn gratis bij te wonen in Hoeve Engelendael aan de Sint-Janspolderdijk in Sint-Jan-in-Eremo.
        </p>

        <a href="https://www.nieuwsblad.be/cnt/dmf20221121_95581274" target="_blank">Lees het artikel via het nieuwsblad</a>

        <h2 className="title">
          Gallerij
        </h2>
        <Gallery images={images}/>
      </main>
    </>
  )}

  export default News25112022;
