import ReactDOM from 'react-dom/client';
import App from './components/App';
import './assets/styles/screen.scss';

import { configure } from 'mobx';

configure({
  enforceActions: "never",
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />
)
