import { observer } from "mobx-react"

import image from '../../assets/images/test.jpg';
import styles from '../../assets/styles/news.module.scss';

const News26042021 = observer(() => {
  return (
    <>
      <div className={styles.imgContainer}>
        <img src={image} alt="" />
      </div>

      <main className={styles.main}>
        <div style={{position: 'relative'}}>
          <h1 className="title">
            Livestream Toerisme Vlaanderen in ‘Hoeve Engelendael’
          </h1>
        </div>

        <div className={styles.article}>
          <p>
            Op 26 april 2021 heeft Toerisme Vlaanderen een livestream georganiseerd te Sint-Jan-in-Eremo met de voorstelling van het Toekomstplan Toerisme in het Meetjesland 2021-2026.
          </p>


          <div className={styles.thanksto}>
            <p>
              Met dank aan de sprekers:
            </p>

            <ul>
              <li>
                Peter De Wilde, CEO Toerisme Vlaanderen.
              </li>
              <li>
                Leentje Grillaert, gedeputeerde Toerisme Provincie Oost-Vlaanderen.
              </li>
              <li>
                Vincent Laroy, Voorzitter Toerisme Meetjesland.
              </li>
              <li>
                Erik Hennes, Regiocoördinator Meetjesland van Toerisme Vlaanderen.
              </li>
              <li>
                Marc Govaert, Hoeve Engelendael.
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.youtube}>
          <p>
            Bekijk hieronder de volledige Livestream
          </p>

          <iframe width="100%" height="600" src="https://www.youtube.com/embed/BPyZuD2-16w?start=5979" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </main>
    </>
  )
})

export default News26042021;

