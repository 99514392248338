import { makeObservable, observable } from "mobx";
import Store from './Store';

interface Modal {
  id: string
  cb: (isOpen: boolean) => void
}

class modalStore extends Store {
  registeredModals: Modal[]
  activeModals: Modal[]
  isModalOpen: boolean

  constructor() {
    super();
    this.activeModals = [];
    this.registeredModals = []
    this.isModalOpen = false

    makeObservable(this, {
      activeModals: observable,
      registeredModals: observable
    });
  }

  registerModal = (modal: Modal) => {
    this.registeredModals.push(modal);
  };

  unregisterModal = (id: string) => {
    this.registeredModals = this.registeredModals.filter(modal => modal.id !== id);
  };

  openModal = (id: string) => {
    const match = this.registeredModals.find(modalData => modalData.id === id);

    if (match !== undefined) {
      match.cb(true);
      this.activeModals.push(match);
      this.isModalOpen = true;
    }
  };

  closeModal = (id: string) => {
    const match = this.registeredModals.find(modal => modal.id === id);

    if (match !== undefined) {
      match.cb(false);
      this.activeModals = this.activeModals.filter(activeModal => activeModal.id !== id);
      this.isModalOpen = this.activeModals.length > 0;
    }
  };

  closeModalWithOverlay = () => {
    if (this.activeModals.length > 0) {
      this.activeModals.forEach(modal => {
        modal.cb(false);
      });

      this.isModalOpen = false;
    }
  };
}

export default new modalStore();



