import styles from '../../assets/styles/news.module.scss';

import image from '../../assets/images/test.jpg';

const News05062012 = () => {
  return (
    <>
      <div className={styles.imgContainer}>
        <img src={image} alt="" />
      </div>

      <main className={styles.main}>
        <div style={{position: 'relative'}}>
          <h1 className="title">De Inbev-Baillet Latour prijs</h1>
        </div>

        <div className={styles.article}>
          <p>
            In 2011 won Engelendael de Inbev-Baillet Latour prijs. De prijs werd uitgereikt door Gouverneur André Denys en Vlaams minister-president Kris Peeters aan de familie Marc Govaert en de Hoeve Engelendael voor de integratie van zachte recreatie in het natuur- en cultuurlandschap van de hoeve in het Krekengebied van het Meetjesland. Deze prijs en erkenning is voor de familie Govaert en hun kinderen een belangrijke stimulans om door te gaan op de ingeslagen weg.
          </p>
        </div>
      </main>
    </>
  )
}

export default News05062012;
