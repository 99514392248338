import { observable, makeObservable } from 'mobx';
import Model from './Model';

export interface IPickupTime {
  id: string;
  date: string;
  day: string;
  day_name: string;
  ordered_amount: number;
  amount: number;
  pickup_times: any[];
  name: string;
  stock: number;
}

class PickupTime extends Model {
  id: string;
  date: string;
  day: string;
  day_name: string;
  ordered_amount: number;
  amount: number;
  pickup_times: any[];
  displayDate: string;

  constructor(data?: IPickupTime) {
    super();
    this.id = data?.id ?? '';
    this.date = data?.date ?? '';
    this.day = data?.day ?? '';
    this.day_name = data?.name ?? '';
    this.ordered_amount = data?.ordered_amount ?? 0;
    this.amount = data?.stock ?? 0;
    this.pickup_times = data?.pickup_times ?? [];

    this.pickup_times =  this.pickup_times.sort((a, b) => a === b ? 0 : a < b ? -1 : 1)
    this.displayDate = `${this.date} - ${this.day_name}`

    makeObservable(this, {
      id: observable,
      date: observable,
      day: observable,
      day_name: observable,
      pickup_times: observable,
    })
  }

  toJSON = () => {
    return {
      id: this.id,
      date: this.date,
      day: this.day,
      day_name: this.day_name,
      pickup_times: this.pickup_times,
    }
  }
}

export default PickupTime


