import { observable, makeObservable } from 'mobx';
import { doFetch } from '../utils';
import { CherriesForm, PickupDate } from '../components/models';
import { IPickupTime } from '../components/models/PickupDate';

const URLS = {
  ORDER_CHERRIES: '/engelendael/orders/order',
  GET_PICKUP_TIMES: '/engelendael/settings/pickup_times'
}

class FormStore {
  pickupDates: PickupDate[];
  loading: boolean;
  enabled: boolean;

  constructor() {
    this.pickupDates = [];
    this.loading = true;
    this.enabled = false;

    makeObservable(this, {
      pickupDates: observable,
      loading: observable,
      enabled: observable,
    });
  };

  initalizeForm = () => {
    const formData = localStorage.getItem('engelendael_form_data');

    if (formData !== null) {
      try {
        return JSON.parse(formData)
      } catch {
        return formData
      }
    }

    return {};
  };

  updateLocalStorageForm = (form: CherriesForm) => {
    const obj = form.toJSON() as any;

    delete obj.amount;
    delete obj.message;

    localStorage.setItem('engelendael_form_data', JSON.stringify(obj))
  };

  clearLocalStorageForm = () => {
    localStorage.removeItem('engelendael_form_data');
  };

  getPickupDates = async () => {
    const { status, data } = await doFetch(URLS.GET_PICKUP_TIMES, 'GET');

    if (status !== 200) {
      throw new Error('FAILED_TO_FETCH_PICKUP_TIMES');
    }

    this.pickupDates = data.map((date: IPickupTime) => new PickupDate(date));
    return this.pickupDates;
  };

  confirmOrder = async (paymentMethod: string, form: CherriesForm) => {
    if (form.saveDetails) {
      this.updateLocalStorageForm(form);
    } else {
      this.clearLocalStorageForm();
    }

    const { data, status } = await doFetch(URLS.ORDER_CHERRIES, 'POST', {
      ...form.toJSON(),
      address: `${form.address}, ${form.postal}`,
      payment_method: paymentMethod
    });

    if (status !== 200) {
      throw ({
        status
      });
    }

    return data;
  };

  getPickupTimes = (date: string) => {
    const pickupDate = this.pickupDates.find(dateData => {
      return dateData.date === date;
    });

    if (pickupDate !== undefined) {
      return pickupDate.pickup_times.map((time: any) => {
        return { text: time, value: time};
      });
    }

    return [];
  };

  getMaxAmount = (date: string) => {
    const pickupDate = this.pickupDates.find(dateData => dateData.date === date);
    const amounts = [];

    if (pickupDate !== undefined) {
      let amount = pickupDate.amount - pickupDate.ordered_amount;

      if (amount > 20) {
        amount = 20;
      }

      for (let i = 0; i < amount; i++) {
        amounts.push({text: `${i + 1}kg`, value: i + 1})
      }
    }

    return amounts;
  }
};


export default new FormStore();

