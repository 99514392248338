import styles from '../../assets/styles/news.module.scss';

import image from '../../assets/images/cherries/cherries-1.jpg';

const News10082023 = () => {
  return (
    <>
      <div className={styles.imgContainer}>
        <img src={image} alt="" />
      </div>

      <main className={styles.main}>
        <div style={{ position: 'relative' }}>
          <h1 className="title">Kersentijd op Hoeve Engelendael</h1>
        </div>

        <div className={styles.authorContainer}>
          <span className={styles.author}>
            Elkje Van Beversluys
          </span>

          <small>09 Augustus 2023</small>
        </div>


        <div className={styles.article}>
          <p>
            Het is volop kersentijd en dat wil zeggen onbeperkt genieten van deze prachtige, zoete vrucht. Maar weet je wat het vraagt om deze favoriete lekkernij te telen? Ik ga op zoek naar de ins en outs van de kersenteelt en trek hiervoor naar het Meetjesland waar Hoeve Engelendael de deuren voor ons wijd opentrekt.
          </p>

          <a href="https://spiceandginger.com/food-stories/kersentijd-op-hoeve-engelendael">Lees de volledige blog hier</a>
        </div>
      </main>
    </>
  )
}

export default News10082023;
