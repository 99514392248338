import Model from './components/models/Model';
import { extendObservable } from 'mobx';

class Validator {
  static input = (message: string) => {
    return (input: any) => {
      return {
        message,
        error: input === "",
      }
    }
  }

  static telno = (message = 'cannot_be_empty') => {
    return (input: any) => {
      return ({
        message,
        error: input.trim() === '' || input.trim().length < 5,
      })
    }
  };



  static duplicate = (message: string, itemToCompare: string) => {
    return (input: any) => {
      return {
        message,
        error: input === "",
      }
    }
  }
  
  static email = (message: string) => {
    return (input: any) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return {
        message,
        error: !re.test(String(input).toLowerCase()),
      };
    }
  }

  static equal = (message: string, itemToCompare: string) => {
    return (input: any) => {
      return {
        message,
        error: input === itemToCompare,
      }
    }
  }

  static number = (message: string) => {
    return (input: number) => {
      return {
        message,
        error: Number.isNaN(input) || input < 10000,
      }
    }
  }

  static initialiseValidation = (model: Model, validation: {[key: string]: Function}) => {

    const keys = Object.keys(validation);
    const obj = {} as {[key: string]: {message: string, error: Boolean}};

    keys.forEach((key) => {
      obj[`error${key}`] = {
        message: '',
        error: false
      };
    });

    extendObservable(model, obj);
  };
}

export default Validator;



