export const getValue = (entity: any, name: string): any => {
  if (!(name in entity)) {
    console.error(`${name} not found in ${entity}`)
  }

  return entity[name as keyof typeof entity]
};

export const getErrorMessage = (entity: any, name: string): { error: boolean, message: string } =>  {
  const error = entity[`error${name}` as keyof typeof entity]

  if (error !== undefined) {
    return error
  }

  return {
    error: false,
    message: ''
  }
};

export const validateStr = (entity: any, name: string, value: string) => {
  try {
    const errFunc = entity.validation[name]

    if (errFunc !== undefined) {
      const result = errFunc(value)
      entity[`error${name}` as keyof typeof entity] = result;
    }
  } catch (error) {
    console.log(error)
  }
};

export const validateInt = (entity: any, name: string, value: number) => {
  try {
    const errFunc = entity.validation[name]

    if (errFunc !== undefined) {
      const result = errFunc(value)
      entity[`error${name}` as keyof typeof entity] = result;
      console.log(`error${name}`)
    }
  } catch (error) {
    console.log(error)
  }
};

export const setInt = (entity: any, name: string, value: number) => {
  entity[name as keyof typeof entity] = value;
};

export const setBool = (entity: any, name: string, value: boolean) => {
  entity[name as keyof typeof entity] = value;
};

export const setStr = (entity: any, name: string, value: string) => {
  entity[name as keyof typeof entity] = value;
};


