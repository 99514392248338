type FormattedMessageProps = {
  id: string;
}

const FormattedMessage = ({ id }: FormattedMessageProps) => {
  return (
    <span>
      {id}
    </span>
  )
};

export default FormattedMessage

