import { observer } from "mobx-react";
import { ContactForm } from "../forms";
import styles from "../../assets/styles/contact.module.scss";
import image from "../../assets/images/banquetHall.png";
import { Map } from "../elements";
import { Contact } from "../partials";

const ContactPage = observer(() => {
  return (
    <main>
      <div className={styles.imgContainer}>
        <img src={image} alt="Achterzijde Hoeve Engelendael" />
      </div>

      <section className={styles.formSection}>
        <h1 className="title">Contacteer ons</h1>
        <div className={styles.formContainer} style={{ marginBottom: "30px" }}>
          <ContactForm hideItems />
        </div>
      </section>

      <Contact />
      <Map />
    </main>
  );
});

export default ContactPage;
