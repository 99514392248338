import styles from '../../assets/styles/news.module.scss';

import image from '../../assets/images/test.jpg';

const News20062020 = () => {
  return (
    <>
      <div className={styles.imgContainer}>
        <img src={image} alt="" />
      </div>

      <main className={styles.main}>
        <div style={{position: 'relative'}}>
          <h1 className="title">Wildlife Estates Label 2020</h1>
        </div>

        <div className={styles.article}>
          <p>
            Op 10 februari 2020 heeft de familie Govaert – van de Bilt het Wildlife Estate Label behaald. Dit
            Europees label is vertegenwoordigd in 19 Europese landen door privé eigenaars. Om dit label te
            behalen heeft de familie tal van inspanningen moeten leveren. Verschillende elementen zoals de
            VLM beheerovereenkomsten voor grasstroken, bloemenranden en voedselgewassen voor faunabeheer,
            het project De Roste Muis, het kleine zwanenproject,… .
          </p>

          <a href="https://landelijk.vlaanderen/de-landeigenaar/thematische-artikels/thematische-artikels-erfgoed/wildlife-estates-label-2020/?fbclid=IwAR243JccgEBlNnXb8GfIQyLSGLh626CvAu-itQcKzu0s1GoHvEDtlcAZwnI" target="_blank" rel="noopener noreferrer">Lees hier het artikel.</a>

        </div>
      </main>
    </>
  )
}

export default News20062020;
