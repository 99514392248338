import { observable, computed, makeObservable } from 'mobx';
import Model from './Model';
import validator from '../../validator';

export interface IMessage {
  name: string;
  email: string;
  address: string;
  telno: string;
  message: string;
  prename: string;
  state: string;
}

class Message extends Model {
  name: string;
  email: string;
  address: string;
  telno: string;
  message: string;
  prename: string;
  state: string;

  constructor(data?: IMessage) {
    super();
    this.name = data?.name ?? '';
    this.email = data?.email ?? '';
    this.address = data?.address ?? '';
    this.telno = data?.telno ?? '';
    this.message = data?.message ?? '';
    this.prename = data?.prename ?? '';
    this.state = data?.state ?? '';

    this.validation = {
      email: validator.email('Vul een geldig e-mail in.'),
      name: validator.input('Vul uw naam in.'),
      address: validator.input('Vul uw adres in.'),
      telno: validator.input('Vul uw telefoonnummer in.'),
      message: validator.input('Vul een bericht in.'),
      prename: validator.input('Vul uw voornaam in.'),
      state: validator.input('Vul uw gemeente in.'),
    };

    validator.initialiseValidation(this, this.validation)

    makeObservable(this, {
      name: observable,
      email: observable,
      address: observable,
      telno: observable,
      message: observable,
      state: observable,
      prename: observable,
    })
  }

  toJS = () => {
    return {
      name: `${this.prename} ${this.name}`,
      email: this.email,
      address: `${this.state} ${this.address}`,
      telno: this.telno,
      message: this.message,
    }
  }
}

export default Message


