import { observable, makeObservable } from 'mobx';

interface INewsItem {
  date: string;
  title: string;
  link: string;
  text: string;
}

class NewsItem {
  date: string;
  title: string;
  link: string;
  text: string;

  constructor(data?: INewsItem) {
    this.date = data?.date ?? '';
    this.title = data?.title ?? '';
    this.link = data?.link ?? '';
    this.text = data?.text ?? '';

    makeObservable(this, {
      date: observable,
      title: observable,
      text: observable,
      link: observable
    })
  }
}



class NewsStore {
  newsItems: NewsItem[];
  selectedItem: NewsItem;

  constructor() {
    this.newsItems = [];
    this.selectedItem = new NewsItem();


    makeObservable(this, {
      newsItems: observable,
      selectedItem: observable
    });
  };

  initialize = () => {
    this.newsItems = [
      new NewsItem({
        date: '09 Augustus 2023',
        link: '/news/10082023',
        title: 'Kersentijd op Hoeve Engelendael',
        text: 'Het is volop kersentijd en dat wil zeggen onbeperkt genieten van deze prachtige, zoete vrucht. Maar weet je wat het vraagt om deze favoriete lekkernij te telen? Ik ga op zoek naar de ins en outs van de kersenteelt en trek hiervoor naar het Meetjesland waar Hoeve Engelendael de deuren voor ons wijd opentrekt.'
      }),
      new NewsItem({
        date: '25 November 2022',
        link: '/news/25112022',
        title: 'Lezing landschappen sint-laureins',
        text: 'Landschappen Sint-Laureins vzw, een open forum voor de cultuurlandschappelijke ontwikkeling van de gemeente en omgeving, houdt vrijdag 25 november een lezing en vervolgens een driedaagse tentoonstelling over het ontstaan van de kreken in het Meetjesland.'
      }),
      new NewsItem({
        date: '26 April 2021',
        link: '/news/26042021',
        title: 'Livestream Toerisme Vlaanderen in ‘Hoeve Engelendael’',
        text: 'Op 26 april 2021 heeft Toerisme Vlaanderen een livestream georganiseerd te Sint-Jan-in-Eremo met de voorstelling van het Toekomstplan Toerisme in het Meetjesland 2021-2026.'
      }),
      new NewsItem({
        date: '10 Juni 2020',
        link: '/news/20062020',
        title: 'Wildlife Estates Label 2020',
        text: 'Op 10 februari 2020 heeft de familie Govaert – van de Bilt het Wildlife Estate Label behaald. Dit Europees label is vertegenwoordigd in 19 Europese landen door privé eigennaars. Om dit label te behalen heeft de familie tal van inspanningen moeten leveren. Verschillende elementen zoals de VLM beheersoverkomsten voor grasstroken, bloemenranden en voedselgewassen voor faunabeheer, het project De Roste Muis project, het kleine zwanenproject,… .'
      }),
      new NewsItem({
        date: '03 Juni 2019',
        link: '/news/03062019',
        title: 'Algemene ledenvergadering landelijk Vlaanderen',
        text: 'Landelijk Vlaanderen vzw  organiseerde op 24 mei 2019 haar algemene ledenvergadering in en rond hoeve Engelendael.    Lees hier het artikel dat daarover werd gepubliceerd in Landelijk Vlaanderen. Engelendael is gelegen in het midden van het Oost-Vlaamse krekengebied. Op het voorprogramma stond een geleide wandeling door Marc Govaert . Tijdens de algemene ledenvergadering stond het Memorandum van Landelijk Vlaanderen en de Koepel van Vlaamse Bosgroepen centraal. Samen zijn ze actief in het aanspreekpunt Privaat Beheer - Natuur en Bos. Ze willen ook in de toekomst maximaal bijdragen aan het realiseren van meer en betere natuur en bos. Ze vragen daartoe een passend kader te scheppen aan de regering. Vonden nog plaats; een toelichting door de experten, een politiek debat, de uitreiking van Wildlife Estates Label en een slottoespraak door Christophe Lenaerts, voorzitter Landelijk Vlaanderen. Alles afgerond door een receptie en netwerkmoment. Het programma kan u hier eens nalezen. '
      }),
      new NewsItem({
        date: '11 Oktober 2018',
        link: '/news/11102018',
        title: 'Landschappen Sint-Laureins',
        text: 'Er was veel belangstelling voor de lezing die op 3 oktober 2018 werd georganiseerd door de vzw Landschappen Sint-Laureins.  Dat onze streken nauwelijks 400 jaar geleden nog het strijdtoneel waren tegen de Spaanse bezetter spreekt tot de verbeelding. In het landschap zijn her en der de overblijfselen daarvan nog zichtbaar en stille getuigen uit een niet ver verleden.'
      }),
      new NewsItem({
        date: '18 Januari 2017',
        link: '/news/18012017',
        title: 'Project herstellen Kreekarm',
        text: ''
      }),
      new NewsItem({
        date: '20 Oktober 2016',
        title: 'Landgoed in de kijker',
        link: "/news/20102016",
        text: 'Voor de editie november 2016 van het tijdschrift Landelijk Vlaanderen werd Marc Govaert geïnterviewd door Alec van Havre, bestuurder Landelijk Vlaanderen en Aanspreekpunt Privaat Beheer.    Lees hier het artikel.  Landelijk Vlaanderen is een organisatie die actief is op verschillende domeinen, waaronder bos, natuur, ruimtelijke ordening, waterbeleid, erfgoed, federale regelgeving & landbouw – en plattelandsontwikkeling.  '
      }),
      new NewsItem({
        date: '05 Juni 2012',
        link: "/news/05062012",
        title: 'De Inbev-Baillet Latour prijs',
        text: 'In 2011 won Engelendael de Inbev-Baillet Latour prijs. De prijs werd uitgereikt door Gouverneur André Denys en Vlaams minister-president Kris Peeters aan de familie Marc Govaert en de Hoeve Engelendael voor de integratie van zachte recreatie in het natuur- en cultuurlandschap van de hoeve in het Krekengebied van het Meetjesland. Deze prijs en erkenning is voor de familie Govaert en hun kinderen een belangrijke stimulans lom door te gaan op de ingeslagen weg. '
      })
    ]
  }
}

export default new NewsStore();

