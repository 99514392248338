import { Link } from 'react-router-dom';
import styles from '../../assets/styles/news.module.scss';

import image from '../../assets/images/test.jpg';

const News20102016 = () => {
  return (
    <>
      <div className={styles.imgContainer}>
        <img src={image} alt="" />
      </div>

      <main className={styles.main}>
        <div style={{position: 'relative'}}>
          <h1 className="title">Landgoed in de Kijker</h1>
        </div>

        <div className={styles.article}>
          <p>
            Voor de editie november 2016 van het tijdschrift Landelijk Vlaanderen werd Marc Govaert geïnterviewd door Alec van Havre, bestuurder Landelijk Vlaanderen en Aanspreekpunt Privaat Beheer. 


            Landelijk Vlaanderen is een organisatie die actief is op verschillende domeinen, waaronder bos, natuur, ruimtelijke ordening, waterbeleid, erfgoed, federale regelgeving & landbouw – en plattelandsontwikkeling.
          </p>

          <Link to="/files/landgoed_in_de_kijker.pdf" target="_blank" download>Lees hier het artikel.</Link>
        </div>
      </main>
    </>
  )
}

export default News20102016;
