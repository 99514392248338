import { useState } from 'react';
import { observer } from 'mobx-react';
import { Gallery } from '../partials/';

import header from '../../assets/images/winter_images/header_2.jpg';
import styles from '../../assets/styles/gallery.module.scss';

import img1 from '../../assets/images/gallery/1.jpg';
import img2 from '../../assets/images/gallery/2.jpg';
import img3 from '../../assets/images/gallery/3.jpg';
import img4 from '../../assets/images/gallery/4.jpg';
import img5 from '../../assets/images/gallery/5.jpg';
import img6 from '../../assets/images/gallery/6.jpg';
import img7 from '../../assets/images/gallery/7.jpg';
import img8 from '../../assets/images/gallery/8.jpg';
import img9 from '../../assets/images/gallery/9.jpg';
import img10 from '../../assets/images/gallery/10.jpg';
import img11 from '../../assets/images/gallery/11.jpg';
import img12 from '../../assets/images/gallery/12.jpg';
import img13 from '../../assets/images/gallery/13.jpg';
import img14 from '../../assets/images/gallery/14.jpg';
import img15 from '../../assets/images/gallery/15.jpg';
import img16 from '../../assets/images/gallery/16.jpg';
import img17 from '../../assets/images/gallery/17.jpg';
import img18 from '../../assets/images/gallery/18.jpg';
import img19 from '../../assets/images/gallery/19.jpg';
import img20 from '../../assets/images/gallery/20.jpg';
import img21 from '../../assets/images/gallery/21.jpg';
import img22 from '../../assets/images/gallery/22.jpg';
import img23 from '../../assets/images/gallery/23.jpg';
import img24 from '../../assets/images/gallery/24.jpg';
import img25 from '../../assets/images/gallery/25.jpg';
import img26 from '../../assets/images/gallery/26.jpg';
import img27 from '../../assets/images/gallery/27.jpg';
import img28 from '../../assets/images/gallery/28.jpg';
import img29 from '../../assets/images/gallery/29.jpg';
import img30 from '../../assets/images/gallery/30.jpg';
import img31 from '../../assets/images/gallery/31.jpg';
import img32 from '../../assets/images/gallery/32.jpg';
import img33 from '../../assets/images/gallery/33.jpg';
import img34 from '../../assets/images/gallery/34.jpg';
import img35 from '../../assets/images/gallery/35.jpg';
import img36 from '../../assets/images/gallery/36.jpg';
import img37 from '../../assets/images/gallery/37.jpg';
import img38 from '../../assets/images/gallery/38.jpg';
import img39 from '../../assets/images/gallery/39.jpg';
import img40 from '../../assets/images/gallery/40.jpg';
import img41 from '../../assets/images/gallery/41.jpg';
import img42 from '../../assets/images/gallery/42.jpg';
import img43 from '../../assets/images/gallery/43.jpg';
import img44 from '../../assets/images/gallery/44.jpg';
import img45 from '../../assets/images/gallery/45.jpg';
import img46 from '../../assets/images/gallery/46.jpg';
import img47 from '../../assets/images/gallery/47.jpg';
import img48 from '../../assets/images/gallery/48.jpg';
import img49 from '../../assets/images/gallery/49.jpg';
import img50 from '../../assets/images/gallery/50.jpg';
import img51 from '../../assets/images/gallery/51.jpg';
import img52 from '../../assets/images/gallery/52.jpg';
import img53 from '../../assets/images/gallery/53.jpg';
import { Contact } from '../partials';


const GenericGallery = observer(() => {
  const [selectedImage, setImage] = useState<undefined | { id: number, src: string }>();

  const images = [
    { id: 1, src: img1 },
    { id: 2, src: img2 },
    { id: 3, src: img3 },
    { id: 4, src: img4 },
    { id: 5, src: img5 },
    { id: 6, src: img6 },
    { id: 7, src: img7 },
    { id: 8, src: img8 },
    { id: 9, src: img9 },
    { id: 10, src: img10 },
    { id: 11, src: img11 },
    { id: 12, src: img12 },
    { id: 13, src: img13 },
    { id: 14, src: img14 },
    { id: 15, src: img15 },
    { id: 16, src: img16 },
    { id: 17, src: img17 },
    { id: 18, src: img18 },
    { id: 19, src: img19 },
    { id: 20, src: img20 },
    { id: 21, src: img21 },
    { id: 22, src: img22 },
    { id: 23, src: img23 },
    { id: 24, src: img24 },
    { id: 25, src: img25 },
    { id: 26, src: img26 },
    { id: 27, src: img27 },
    { id: 28, src: img28 },
    { id: 29, src: img29 },
    { id: 30, src: img30 },
    { id: 31, src: img31 },
    { id: 32, src: img32 },
    { id: 33, src: img33 },
    { id: 34, src: img34 },
    { id: 35, src: img35 },
    { id: 36, src: img36 },
    { id: 37, src: img37 },
    { id: 38, src: img38 },
    { id: 39, src: img39 },
    { id: 40, src: img40 },
    { id: 41, src: img41 },
    { id: 42, src: img42 },
    { id: 43, src: img43 },
    { id: 44, src: img44 },
    { id: 45, src: img45 },
    { id: 46, src: img46 },
    { id: 47, src: img47 },
    { id: 48, src: img48 },
    { id: 49, src: img49 },
    { id: 50, src: img50 },
    { id: 51, src: img51 },
    { id: 52, src: img52 },
    { id: 53, src: img53 }
  ];

  return (
    <main>
      <div className={styles.imgContainer}>
        <img src={header} alt="" />
      </div>

      <div className={styles.title}>
        <h1 className="title">Galerij</h1>
      </div>

      <Gallery images={images} />

      <Contact />
    </main>
  )
});

export default GenericGallery;

