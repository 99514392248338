import { observer } from 'mobx-react-lite';
import Model from '../models/Model';
import Store from '../../stores/Store';
import { 
  getValue,
  getErrorMessage,
  validateInt,
  validateStr,
  setInt,
  setStr,
} from './IO';

import { translate } from '../../utils';

import styles from '../../assets/styles/inputs.module.scss'
import { globalStore } from '../../stores';

interface props {
  entity: Model | Store,
  name: string
  placeholder?: string,
  type?: string,
  className?: string,
  disableAutoFill?: boolean,
  disabled?: boolean,
}

const Input = observer(({
    entity,
    name,
    placeholder = '',
    type = 'text',
    className = '',
    disableAutoFill = false,
    disabled = false,
}: props) => { 
  const { lang } = globalStore;

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    switch(type) {
      case 'number':
        setInt(entity, name, parseInt(ev.target.value, 10))
        break;

      default:
        setStr(entity, name, ev.target.value);
        break;
    }
  };

  const onValidate = (ev: React.ChangeEvent<HTMLInputElement>) => {
    switch(type) {
      case 'number':
        validateInt(entity, name, parseInt(ev.target.value, 10))
        break;

      default:
        validateStr(entity, name, ev.target.value);
        break;
    }
  };

  const { error, message } = getErrorMessage(entity, name);
  const value = getValue(entity, name)
  const translatedPlaceholder = translate(lang, placeholder)

  return (
    <div className={styles.inputContainer}>
      <input 
        className={className}
        type={type}
        onChange={onChange}
        value={value}
        onBlur={onValidate}
        placeholder={translatedPlaceholder}
        autoComplete={disableAutoFill ? 'none' : ''}
        disabled={disabled}
      />

      {
        error && (
          <span className={styles.error}>
            {message}
          </span>
        )
      }
    </div>
  )
})

export default Input;
