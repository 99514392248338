import { useLocation } from "react-router-dom";
import { useRedirect } from "../../hooks";

import styles from "../../assets/styles/footer.module.scss";

const Footer = () => {
  const { redirect } = useRedirect();
  const { pathname } = useLocation();

  const goToPrivacy = () => {
    redirect("/privacy");
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.policies}>
        <span onClick={goToPrivacy}>
          Bekijk ons <strong>privacy beleid</strong>
        </span>

        <span>© Hoeve Engelendael {new Date().getFullYear()}</span>
      </div>

      <div
        className={styles.baseContent}
        style={{
          backgroundColor: "#353535",
        }}
      >
        <div className={styles.madeBy}>
          Website by{" "}
          <a href="https://digitalpeak.be" target="_blank">
            DigitalPeak
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
