import { Model } from './components/models/';

export const doFetch = async (url: string, method: string, data?: any): Promise<{status: number, data: any}>  => {
  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
    },
    body: method !== 'GET' ? JSON.stringify(data) : undefined // body data type must match "Content-Type" header
  });

  const parseData = await response.json();

  return {
    status: response.status,
    data: parseData,
  }
}


export const controlOnErrors = (entity: Model, validation?: any) => {
  return new Promise((resolve, reject) => {
    let hasErrors = false
    let keys = undefined;

    if (validation !== undefined) {
      keys = Object.keys(validation)
    } else {
      keys = Object.keys(entity.validation)
    }

    if (keys === undefined) {
      reject("failed to find keys")
      return
    }

    const errors: string[] = [];

    keys.forEach((key: string) => {
      const valueToValidate = entity[key as keyof typeof entity] 
      const errFunc = validation !== undefined ? validation[key] : entity.validation[key]

      if (errFunc !== undefined) {
        const result = errFunc(valueToValidate)
        if (result.error) {
          errors.push(result.message)
          hasErrors = true;
        }

        entity[`error${key}` as keyof typeof entity] = result;
      }
    })

    if (hasErrors) {
      reject(errors)
    }

    resolve(true);
  })
}



export const translate = (lang: string, id: string, replacements?: {[key: string]: string}): string => {
  return id
};

