import { observable, makeObservable } from 'mobx';
import { Message } from '../components/models';
import { doFetch } from '../utils';

const URLS = {
  SEND_MESSAGE: `/engelendael/contact`
};

class ContactStore {
  message: Message;
  lastMessage: Message;

  constructor() {
    this.message = new Message();
    this.lastMessage = new Message();

    makeObservable(this, {
      message: observable,
      lastMessage: observable
    });
  };

  sendMessage = async () => {
    await doFetch(URLS.SEND_MESSAGE, 'POST', {
      ...this.message.toJS()
    });

    this.lastMessage = this.message;
    this.message = new Message();
  };

  resetMessage = () => {
    this.message = new Message();
  };
};


export default new ContactStore();

