import { extendObservable, computed } from 'mobx';

class Model {
  validation: any
  activeErrors: {message: string, error: Boolean}[];
  originalData: string;

  constructor() {
    this.validation = {}
    this.activeErrors = [];
    this.originalData = '';

    extendObservable(this, {
      activeErrors: computed,
    })
  }

  setOriginalData = (obj: any) => {
    this.originalData = JSON.stringify(obj);
  }

  resetErrors = () => {
    Object.keys(this).forEach(key => {
      if (key.includes('error')) {
        const error = this[key as keyof typeof this] as any
        error.error = false
      }
    });
  };

  getActiveErrors = () => {
    const keys = Object.keys(this).filter(key => {
      const error = this[key as keyof typeof this] as any
      return key.includes('error') && error.error
    });

    return keys.map((error) => {
      const item = this[error as keyof typeof this];
      return item
    });
  }
}

export default Model


